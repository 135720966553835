/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo, useRef } from 'react'
import CandidateTestScreens from './CandidateTestScreens.js'
import { useDispatch, useSelector } from 'react-redux'
import { getTestInfo, startTest } from '../../Store/testSlice.js'
import TestSubmit from './TestSubmit.js'
import { useLocation } from 'react-router'
import { t } from 'i18next'
import Loader from '../Common/Loader.js'
import Webcam from 'react-webcam'
import AlertModal from '../Modal/AlertModal.js'

const CandidateTest = () => {
  const startTestData = useSelector((state) => state.test.startTest)
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [testInfoData, setTestInfoData] = useState(null)
  const [assessment_url, setAssessment_url] = useState(null)
  const [testSubmited, setTestSubmited] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isPemissionDenied, setIsPermissionDenied] = useState(false)
  const [warningMsg, setWarningMsg] = useState('')
  const [streamMediaInfo, setStreamMediaInfo] = useState(null)
  const webcamRef = useRef(null)
  // const [captureInterval, setCaptureInterval] = useState(null)
  const [isAudio, setIsAudio] = useState(true)
  const [webcamReady, setWebcamReady] = useState(false)

  // const [capturedImage, setCapturedImage] = useState(null)

  const dispatch = useDispatch()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')

  const toggleFullscreen = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      })
      if (stream) {
        setStreamMediaInfo(stream)
      }
      const element = document.documentElement
      setIsLoading(true)

      try {
        if (document.fullscreenEnabled) {
          if (document.fullscreenElement) {
            await document.exitFullscreen()
            setIsFullscreen(false)
            setTestSubmited(false)
          } else {
            const obj = {
              testUrl: assessment_url,
              candidateId: id,
            }

            // Call the startTest API
            const response = await dispatch(startTest(obj))

            // Check if the API call was successful and status is true
            if (response.payload.status) {
              setIsLoading(false)

              await element.requestFullscreen()
              setIsFullscreen(true)
            } else {
              
              // setTestSubmited(true);
              setIsLoading(false)
            }
          }
        } else {
          // console.error('Fullscreen is not supported')
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)
      }
    } catch (error) {
    
      if (error.name === t('notAllowed')) {
        setWarningMsg(t('AllowAccess'))
        setIsPermissionDenied(true)
      } else if (error.name === t('notFound')) {
        setWarningMsg(t('notFoundWarning'))
        setIsPermissionDenied(true)
      } else {
        setWarningMsg(error?.message)
        setIsPermissionDenied(true)
      }
    }
  }

  const testSubmitComponent = useMemo(() => {
    setIsLoading(false)
    return testSubmited && <TestSubmit />
  }, [testSubmited])

  const candidateTestScreensComponent = useMemo(() => {
    return (
      isFullscreen && (
        <CandidateTestScreens
          isFullscreen={isFullscreen}
          toggleFullscreen={toggleFullscreen}
          candidateId={id}
          assessment_url={assessment_url}
          testInfoData={testInfoData}
          setTestSubmited={setTestSubmited}
          setIsFullscreen={setIsFullscreen}
          streamMediaInfo={streamMediaInfo}
          setIsAudio={setIsAudio}
          webcamRef={webcamRef}
          setStreamMediaInfo={setStreamMediaInfo}
          webcamReady={webcamReady}
        />
      )
    )
  }, [isFullscreen, id, assessment_url, testInfoData, setTestSubmited])

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)

      try {
        // Fetching startTestData
        if (startTestData && startTestData.status) {
          setIsFullscreen(false)
          setIsLoading(false)
        } else {
          setIsFullscreen(false)
          setIsLoading(false)
        }

        // Fetching testInfo
        const testInfoResponse = await dispatch(
          getTestInfo({ candidateId: id })
        )
        if (
          testInfoResponse &&
          testInfoResponse.payload &&
          testInfoResponse.payload.assesmentDetails
        ) {
          setTestInfoData(testInfoResponse.payload.assesmentDetails)
          setAssessment_url(
            testInfoResponse.payload.assesmentDetails.assessment_url
          )
          setIsLoading(false)
        } else {
          setIsLoading(false)
        }
      } catch (error) {
        
        setIsLoading(false)
      }
    }

    fetchData()
  }, [dispatch, id])

  const handleCopyPaste = (e) => {
    e.preventDefault()
  }

  const showAlertModal = useMemo(() => {
    return (
      <AlertModal
        setIsPermissionDenied={setIsPermissionDenied}
        isPemissionDenied={isPemissionDenied}
        message={warningMsg}
      />
    )
  }, [isPemissionDenied])

  if (isLoading) {
    return <Loader />
  }

  // className="hidden-webcam"
  return (
    <div>
      <Webcam
        ref={webcamRef}
        audio={isAudio}
        screenshotFormat="image/jpeg"
        videoConstraints={{
          width: 640,
          height: 480,
          // eslint-disable-next-line quotes
          facingMode: 'user', // This uses the front camera if available
        }}
        style={{
          position: 'absolute',
          width: '640px',
          height: '480px',
          opacity: 0, // Makes the webcam invisible
          zIndex: -1, // Puts it behind other content
        }}
        onUserMedia={() => setWebcamReady(true)} 
        // onUserMediaError={(error) =>{ }}
      />
      {!isFullscreen && !testSubmited && (
        <>
          <div
            className="w-full flex items-center justify-center bgc-color h-screen"
            onCopy={handleCopyPaste}
            onPaste={handleCopyPaste}
          >
            <div className="w-full lg:w-6/12 border bg-white shadow-md rounded-lg px-4 py-4">
              <div className=" font-semibold text-xl lg:text-2xl xl:text-2xl font">
                {t('sampleTest')}
              </div>
              <div className="flex flex-col mt-4 text-sm lg:text-xl xl:text-xl">
                <div className="mr-10">
                  {t('testName')}
                  {testInfoData?.assessmentName}
                </div>
                <div className="mr-10 mt-2">
                  {t('testDuration')}
                  {testInfoData?.assessment_duration} {t('min')}
                </div>
              </div>
              <div className="">
                <div className="text-sm lg:text-xl xl:text-xl font-semibold mt-4">
                  {t('readInstructions')}
                </div>
                <ul className="list-disc mt-3">
                  <li className="text-sm lg:text-xl xl:text-xl">
                    {t('usingGoogleChrome')}{' '}
                  </li>
                  <li className="text-sm lg:text-xl xl:text-xl">
                    {t('enableCamera&micro')}
                  </li>
                  <li className="text-sm lg:text-xl xl:text-xl">
                    {t('dontSwitchOff')}
                  </li>
                  <li className="text-sm lg:text-xl xl:text-xl">
                    {t('disableAllAdBlockers')}
                  </li>
                  <li className="text-sm lg:text-xl xl:text-xl">
                    {t('weRecommend')}
                  </li>
                  <li className="text-sm lg:text-xl xl:text-xl">
                    {t('internetConnection')}
                  </li>
                </ul>
              </div>
              <div className="mt-4">
                <button
                  data-testid="start-btn"
                  onClick={toggleFullscreen}
                  className="bg-green-500 text-white px-4 py-2 rounded-lg"
                >
                  {t('startTest')}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {testSubmitComponent}
      {candidateTestScreensComponent}
      {showAlertModal}
    </div>
  )
}

export default CandidateTest
