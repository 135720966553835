/*eslint-disable no-undef*/
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { useEffect, useRef, useState } from 'react'
import { t } from 'i18next'
import { FaCamera } from 'react-icons/fa'
import { AiFillAudio } from 'react-icons/ai'
import { useSelector } from 'react-redux'

function SnapshotsAndAudios({ isOpen, setIsOpen }) {
  const toggle = () => {
    setIsOpen(!isOpen)
  }
  const candidateData = useSelector(
    (state) => state.candidates.getCandidateById
  )
  const audioRefs = useRef([])
  const [showSnapshots, setShowSnapshots] = useState(false)
  const [showAudioClips, setShowAudioClips] = useState(false)
  const [images, setImages] = useState([])
  const [selectedImage, setSelectedImage] = useState(null)
  const [audios, setAudios] = useState([])
  const [currentAudioIndex, setCurrentAudioIndex] = useState(null)

  const handleImageClick = (image) => {
    setSelectedImage(image) // Set the clicked image to be viewed in larger size
  }

  const handleCloseImage = () => {
    setSelectedImage(null) // Close the enlarged image
  }

  // To handle only one audio element plays at a time
  const handlePlay = (index) => {
    if (currentAudioIndex !== null && currentAudioIndex !== index) {
      audioRefs.current[currentAudioIndex].pause()
      audioRefs.current[currentAudioIndex].currentTime = 0 // Optionally reset the previous audio
    }
    setCurrentAudioIndex(index)
  }

  useEffect(() => {
    setImages(candidateData?.candidatedata?.assessmentSnapshots)
    setAudios(candidateData?.candidatedata?.audioClips)
  }, [candidateData])

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{t('snapshotsAndAudios')}</ModalHeader>
      <ModalHeader
        className="text-center cursor-pointer hover:bg-slate-200"
        onClick={() => setShowSnapshots(!showSnapshots)}
      >
        <div className="flex justify-start items-center text-center w-full ">
          <div className="rounded-full bg-primary p-2">
            <FaCamera className="text-xl text-light" />
          </div>
          <div className="text-center ml-4 text-sm">Captured Snapshots</div>
        </div>
      </ModalHeader>
      {showSnapshots && (
        <div className="p-4">
          <div className="grid grid-cols-3 gap-4">
            {images.length > 0 ? (
              images.map((image, index) => (
                <div key={index} className="mb-4">
                  <img
                    src={image}
                    alt={`Snapshot ${index + 1}`}
                    className="w-full h-auto rounded-lg cursor-pointer transform transition-transform duration-200 hover:scale-110"
                    onClick={() => handleImageClick(image)} // On image click, show it enlarged
                  />
                </div>
              ))
            ) : (
              <div className="text-center col-span-3">{t('noImages')}</div>
            )}
          </div>
        </div>
      )}
      {/* Full-screen Image Modal */}
      {selectedImage && (
        <div className="fixed inset-0 z-1 bg-black bg-opacity-75 flex items-center justify-center">
          <button
            onClick={handleCloseImage}
            className="absolute top-5 right-5 text-white text-2xl font-bold"
          >
            ✕
          </button>
          <img
            src={selectedImage}
            alt="Enlarged snapshot"
            className="max-w-full max-h-full rounded-lg"
          />
        </div>
      )}
      <ModalHeader
        className="text-center cursor-pointer hover:bg-slate-200"
        onClick={() => setShowAudioClips(!showAudioClips)}
      >
        <div className="flex justify-start items-center text-center w-full ">
          <div className="rounded-full bg-green-600 p-2">
            <AiFillAudio className="text-xl text-light" />
          </div>
          <div className="text-center ml-4 text-sm">{t('microphoneRecordings')}</div>
        </div>
      </ModalHeader>
      {showAudioClips && (
        <ModalBody>
          <div className="p-2">
            <div className="grid grid-cols-2 gap-4">
              {audios?.length > 0 ? (
                audios?.map((audioSrc, index) => (
                  <div key={index} className="mb-2">
                    <audio
                      ref={(el) => (audioRefs.current[index] = el)}
                      controls
                      src={audioSrc}
                      style={{ width: '215px', height: '45px' }}
                      onPlay={() => handlePlay(index)}
                    />
                  </div>
                ))
              ) : (
                <div className="text-center col-span-3">{t('noAudios')}</div>
              )}
            </div>
          </div>
        </ModalBody>
      )}
    </Modal>
  )
}

export default SnapshotsAndAudios
/*eslint-enable no-undef*/
