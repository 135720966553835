import ReactApexChart from 'react-apexcharts'
import {
  Chart as ChartJS,
  PolarAreaController,
  RadialLinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js'
import Loader from '../Common/Loader'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSuperAdminDashboard } from '../../Store/dashboardSlice'
import { LuNewspaper } from 'react-icons/lu'
import { FaUsers } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { size } from 'lodash'
ChartJS.register(
  PolarAreaController,
  RadialLinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Tooltip,
  Legend
)

const AssessorDashboard = ({ assessorData }) => {
  const navigate = useNavigate()
  const [assData, setAssData] = useState([])
  const [isAssStatus, setIsAssStatus] = useState('')
  const [assessmentCount, setAssessmentCount] = useState(null)
  const [listOfAssName, setListOfAssName] = useState([])
  const handleAssClick = (label) => {
    navigate('/assessments', { state: { label: label } })
  }

  const handleStatusNavigate = (label, obj) => {
    navigate(`/assessments/${obj?.AssessmentId}/${obj?.AssessmentUrl}`, {
      state: { status: label },
    })
  }

  const handleScoreNavigate = (label, obj) => {
    navigate(`/assessments/${obj?.AssessmentId}/${obj?.AssessmentUrl}`, {
      state: { score: label },

      // label === '<50' ? 'Less Than' : label === '>91' ?  'Greater Than' : label === '50 - 75' || label === '76 - 90' ? 'Between':  ''
    })
  }

  var options = {
    series: [
      {
        name: 'Completed', // Main series for bar heights
        data: assessorData?.data?.data?.Completed || [],
      },
      {
        name: 'Invited', // Series for hover data (e.g., metric1)
        data: assessorData?.data?.data?.Invited || [],
      },
      {
        name: 'In progress', // Series for hover data (e.g., metric1)
        data: assessorData?.data?.data?.In_Progress || [],
      },
      {
        name: 'Waiting', // Series for hover data (e.g., metric1)
        data: assessorData?.data?.data?.Waiting || [],
      },
      // ... add more series for other additional data sets
    ],
    chart: {
      type: 'bar',
      stacked: true,
      // stackType: '100%',
      events: {
        legendClick: (chartContext, seriesIndex, config) => {
          const label = config.config.series[seriesIndex]
        
          // if (label) {
          //   handleAssClick(label)
          // }
        },
        dataPointSelection: (event, chartContext, config) => {
          const Index = config.seriesIndex
          const label = config.w.config.series[Index]
          const dataPointIndex = config.dataPointIndex
          const assName = config.w.config.xaxis.categories[dataPointIndex]
     
          const data = assessorData?.data?.assessorAssessmentNameIdUrl

          const obj = data?.find((v) => {
            let newAssName;
            if (v.AssessmentName.length > 6) {
              newAssName = v.AssessmentName.substring(0, 6) + '...'
            } else {
              newAssName = v.AssessmentName;
            }
            return newAssName === assName;
          }

          )
          if (label.name) {
            handleStatusNavigate(label.name, obj)
          }
        },
      },
    },
    colors: ['#5dd55d', '#3498DB','#ffc107', '#ff4d4d'],
    // colors: ['#ff4d4d', '#FFB347','#D9FF00', '#5dd55d'],
    title: {
      style: {
        color: 'black',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '13px',
        fontWeight: 600,
      },
      text: 'Candidates Status Per Assessment',
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: 'end',
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: assessorData?.data?.assessorAssessmentNameIdUrl.map(v =>
        v.AssessmentName.length > 6 ? v.AssessmentName.substring(0, 6) + '...' : v.AssessmentName
      ), // Truncated names for x-axis display
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const fullName = assessorData?.data?.assessorAssessmentNameIdUrl[dataPointIndex]?.AssessmentName;
  
        // Adjust the dot size and spacing here
        const colorDot = `<span style="display:inline-block;width:12px;height:12px;background-color:${w.globals.colors[seriesIndex]};border-radius:50%;margin-right:8px;margin-left:5px;"></span>`;
  
        return `<div class="apexcharts-tooltip-title" style="font-size:small">${fullName}</div>
              <div style="font-size:small">${colorDot}${w.globals.seriesNames[seriesIndex]}: <strong>${series[seriesIndex][dataPointIndex]}</strong></div>`;
      },
    },
   
    responsive: [
      {
        breakpoint: 2560,
        options: {
          chart: {
            width: 770,
            height: 300,
          },
          legend: {
            position: 'bottom',
            fontSize: '12px'
          },
        },
      },
      {
        breakpoint: 1695,
        options: {
          chart: {
            width: 630,
            height: 305,
          },
          legend: {
            position: 'bottom',
            fontSize: '10px',
          },
        },
      },
      {
        breakpoint: 1477,
        options: {
          chart: {
            width: 550,
            height: 250,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
      {
        breakpoint: 1355,
        options: {
          chart: {
            width: 480,
            height: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
      {
        breakpoint: 1250,
        options: {
          chart: {
            width: 450,
            height: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
      {
        breakpoint: 1165,
        options: {
          chart: {
            width: 400,
            height: 180,
          },
          legend: {
            position: 'bottom',
          },
        },
      },

      {
        breakpoint: 989,
        options: {
          chart: {
            width: 380,
            height: 180,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
      {
        breakpoint: 865,
        options: {
          chart: {
            width: 320,
            height: 180,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
      {
        breakpoint: 640,
        options: {
          chart: {
            width: 400,
            height: 300,
          },
          legend: {
            position: 'bottom',
          },

        },
      },
      {
        breakpoint: 528,
        options: {
          chart: {
            width: 300,
            height: 300,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
      {
        breakpoint: 434,
        options: {
          chart: {
            width: 250,
            height: 300,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    'locales': [
      null
  ]
  }

  var options2 = {
    series: [
      {
        name: '<50', // Main series for bar heights
        data: assessorData?.data?.data2?.lessThan50 || [],
      },
      {
        name: '50 - 75', // Series for hover data (e.g., metric1)
        data: assessorData?.data?.data2?.Bet50To75 || [],
      },
      {
        name: '76 - 90', // Series for hover data (e.g., metric1)
        data: assessorData?.data?.data2?.Bet76To90 || [],
      },
      {
        name: '>91', // Series for hover data (e.g., metric1)
        data: assessorData?.data?.data2?.Above91 || [],
      },
      // ... add more series for other additional data sets
    ],
    chart: {
      type: 'bar',
      stacked: true,
      events: {
        legendClick: (chartContext, seriesIndex, config) => {
          const label = config.config.series[seriesIndex]

          // if (label) {
          //   handleAssClick(label)
          // }
        },
        dataPointSelection: (event, chartContext, config) => {
          const Index = config.seriesIndex
          var label = config.w.config.series[Index]
          const dataPointIndex = config.dataPointIndex
          const assName = config.w.config.xaxis.categories[dataPointIndex]
         
          const data = assessorData?.data?.assessorAssessmentNameIdUrl
          const obj = data?.find((v) => {
            let newAssName;
            if (v.AssessmentName.length > 6) {
              newAssName = v.AssessmentName.substring(0, 6) + '...'
            } else {
              newAssName = v.AssessmentName;
            }
            return newAssName === assName;
          }   )  
            if (label.name) {
            handleScoreNavigate(label.name, obj)
          }
        },
      },
    },
    // colors: ['#e60026', '#FFA500','#BFCF6E', '#5dd55d'],
    colors: ['#ff4d4d', '#FFB347','#D9FF00', '#5dd55d'],
    title: {
      style: {
        color: 'black',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '13px',
        fontWeight: 600,
      },

      text: 'Percentage Range Analysis Per Assessment',
      // colors: ['red']
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: 'end',
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: assessorData?.data?.assessorAssessmentNameIdUrl.map(v =>
        v.AssessmentName.length > 6 ? v.AssessmentName.substring(0, 6) + '...' : v.AssessmentName
      ), // Truncated names for x-axis display
      
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const fullName = assessorData?.data?.assessorAssessmentNameIdUrl[dataPointIndex]?.AssessmentName;
  
        // Adjust the dot size and spacing here
        const colorDot = `<span style="display:inline-block;width:12px;height:12px;background-color:${w.globals.colors[seriesIndex]};border-radius:50%;margin-right:8px;margin-left:5px;"></span>`;
  
        return `<div class="apexcharts-tooltip-title" style="font-size:small">${fullName}</div>
        <div style="font-size:small">${colorDot}${w.globals.seriesNames[seriesIndex]}: <strong>${series[seriesIndex][dataPointIndex]}</strong></div>`;
      },
    },

    responsive: [
      {
        breakpoint: 2560,
        options: {
          chart: {
            width: 770,
            height: 300,
          },
          legend: {
            position: 'bottom',
            fontSize: '12px'
          },
        },
      },
      {
        breakpoint: 1695,
        options: {
          chart: {
            width: 630,
            height: 305,
          },
          legend: {
            position: 'bottom',
            fontSize: '10px',
          },
        },
      },
      {
        breakpoint: 1477,
        options: {
          chart: {
            width: 550,
            height: 250,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
      {
        breakpoint: 1355,
        options: {
          chart: {
            width: 480,
            height: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
      {
        breakpoint: 1245,
        options: {
          chart: {
            width: 450,
            height: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
      {
        breakpoint: 1165,
        options: {
          chart: {
            width: 400,
            height: 180,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
      {
        breakpoint: 989,
        options: {
          chart: {
            width: 380,
            height: 180,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
      {
        breakpoint: 865,
        options: {
          chart: {
            width: 320,
            height: 180,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
      {
        breakpoint: 640,
        options: {
          chart: {
            width: 400,
            height: 300,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
      {
        breakpoint: 528,
        options: {
          chart: {
            width: 300,
            height: 300,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
      {
        breakpoint: 434,
        options: {
          chart: {
            width: 250,
            height: 300,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    'locales': [
      null
  ]
  }

  const assesmentStatus = {
    series: assData || [],
    options: {
      chart: {
        type: 'donut',
        events: {
          legendClick: (chartContext, seriesIndex, config) => {
            const label = config.config.labels[seriesIndex]
      
            if (label) {
              handleAssClick(label)
            }
          },
          dataPointSelection: (event, chartContext, config) => {
            const label = config.w.config.labels[config.dataPointIndex]
        
            if (label) {
              setIsAssStatus(label)
            }
          },
        },
      },
      tooltip: {
        enabled: true,
        onDatasetHover: {
          highlightDataSeries: false,
        },
        y: {
          formatter: function (
            value,
            { series, seriesIndex, dataPointIndex, w, config }
          ) {
            // console.log('value', series,seriesIndex,dataPointIndex,w,config)
            const label = config.labels[seriesIndex]

            // eslint-disable-next-line no-useless-concat
            const count = assessmentCount[label]
            if (count !== undefined) {
              return count
            }
            return value
          },
        },
      },
      // '#e60026',
      colors: ['#5dd55d', '#ff4d4d'],
      labels: ['Active', 'Inactive'] || [],
      responsive: [
        {
          breakpoint: 2560,
          options: {
            chart: {
              width: 650,
              height: 600,
            },
            legend: {
              position: 'right',
              fontSize: '12px'
            },
          },
        },
        {

          breakpoint: 1675,
          options: {
            chart: {
              width: 550,
              height: 550,
            },
            legend: {
              position: 'right',
              // formatter: (seriesName, opts) => {
              //   console.log('seriesName', seriesName)
              //   return (
              //     seriesName + ':  ' + opts.w.globals.series[opts.seriesIndex] + '%'
              //   )
              // },
            },
          },
        },
        {

          breakpoint: 1340,
          options: {
            chart: {
              width: 300,
              height: 300,
            },
            legend: {
              position: 'bottom',

            },
          },
        },
        {

          breakpoint: 1024,
          options: {
            chart: {
              width: 300,
              height: 300,
            },
            legend: {
              position: 'right',

            },
          },
        },
        {
          breakpoint: 845,
          options: {
            chart: {
              width: 380,
              height: 380,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
        {
          breakpoint: 768,
          options: {
            chart: {
              width: 350,
              height: 250,
            },
            legend: {
              position: 'right',
            },
          },
        },

        {
          breakpoint: 640,
          options: {
            chart: {
              width: 300,
              height: 250,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
    'locales': [
      null
  ]
  }

  useEffect(() => {
    if (isAssStatus) {
      handleAssClick(isAssStatus)
    }
  }, [isAssStatus])

  // useEffect(() => {

  //   handleNavigate()
  // }, [dataPointName])

  useEffect(() => {
    const assessmentData = assessorData?.data?.assessments_status
    setAssessmentCount(assessorData?.data?.assessmentCounts)
    // console.log('assessmentData ==', assessmentData)
    if (assessmentData?.length > 0) {
      const actualData = assessmentData.map((str) => parseFloat(str))
      setAssData(actualData)
      const listOfAss = assessorData?.data?.data?.assessmentNames;
      const updatedList = listOfAss.map(assName => (
        assName?.length > 6 ? assName.substring(0, 6) + '...' : assName
      ))
      setListOfAssName(updatedList)
    } else {
      // console.log('no data heer')
    }
  }, [assessorData])

  return (
    <>
      <div
        data-testid="dashboard"
        className="plt bgc-color xl:overflow-hidden lg:overflow-hidden md:overflow-hidden sm:overflow-hidden dashboard flex flex-col sm:flex-col md:flex-row md:justify-between lg:flex-row lg:justify-between xl:flex-row xl:justify-between w-100"
      >
        <div className="flex xl:flex-col lg:flex-col md:flex-col sm:flex-row xs:flex-col  xl:w-[50%] lg:w-[50%] md:w-[50%] sm:w-[100%] xs:h-[52vh] sm:h-[41vh] md:h-[63vh] lg:h-[83vh] xl:h-[83vh] 2xl:h-[83vh]  xl:justify-between">

          <div className="flex flex-col mr-2 xl:flex-row  lg:flex-row md:flex-col sm:flex-col xl:justify-between lg:justify-between md:justify-between  xl:h-20 lg:h-20 md:h-20 sm:h-96  statistics xl:w-[100%] lg:w-[100%] md:w-[100%] sm:w-[50%]">
            <div className="xl:w-[49%] sm:h-36 md:h-20 box d-flex rounded-2 align-items-center  p-3 shadow-md">
              <i className="uil-envelope-shield fs-2 text-center bg-primary rounded-circle flex justify-center items-center">
                <LuNewspaper className="text-light" />
              </i>
              <div className="ms-3">
                <div className="d-flex align-items-center">
                  <h3 className="mb-0">
                    {assessorData?.counts?.totalAssessments
                      ? assessorData?.counts?.totalAssessments
                      : 0}
                  </h3>{' '}
                  <span className="d-block ms-2">Assessments</span>
                </div>
                <p className="fs-normal mb-0 text-sm">Total Assessments</p>
              </div>
            </div>
            <div className="xl:w-[49%] sm:h-36 md:h-20 xs:mt-2 md:mt-1 lg:mt-0  box d-flex rounded-2 align-items-center p-3 shadow-md">
              <i className="uil-users-alt fs-2 text-center bg-success rounded-circle flex justify-center items-center">
                <FaUsers className="text-light" />
              </i>
              <div className="ms-3">
                <div className="d-flex align-items-center">
                  <h3 className="mb-0">
                    {assessorData?.counts?.totalCandidates
                      ? assessorData?.counts?.totalCandidates
                      : 0}                          </h3>{' '}
                  <span className="d-block ms-2">Candidates</span>
                </div>
                <p className="fs-normal mb-0 text-sm">Total Candidates</p>
              </div>
            </div>
          </div>
          <div className={`bg-white xl:h-[90vh] lg:h-[80vh] md:h-[80vh] sm:h-[40vh] rounded xs:mt-2 sm:mt-0 xl:mt-2 lg:mt-1 md:mt-24 xl:ml-0 lg:ml-0 md:ml-0 sm:ml-3
`}
          >
            <>
              <p className="xs:text-xs md:text-sm font-semibold font p-3">
                Assessment By Status
              </p>

              {assData?.length > 0 ? (
                <ReactApexChart
                  options={assesmentStatus.options}
                  series={assesmentStatus.series}
                  type="donut"
                  className="chart flex justify-center items-center"
                />
              ) : (
                <div className="flex justify-center items-center">
                  No Assesment Here
                </div>
              )}
            </>
          </div>
        </div>

        <div className="xl:ml-2 lg:ml-3 md:ml-3 flex flex-col  xl:w-[55%] lg:w-[60%] md:w-[50%] sm:w-[100%] xs:w-[100%] lg:h-[83vh] xl:h-[83vh] xs:h-[83vh] xl:mt-0 lg:mt-0 md:mt-0 sm:mt-0 xs:mt-28 xl:mb-0 xs:mb-5 xl:justify-between">
          <div className="xl:h-[49%] md:h-[50%] bg-white shadow-sm p-2 rounded xs:text-xs sm:text-sm">
            {assessorData?.data?.data?.Completed.length > 0 &&
              assessorData?.data?.data?.In_Progress.length > 0 &&
              assessorData?.data?.data?.Invited.length > 0 &&
              assessorData?.data?.data?.Waiting.length > 0 ? (
              //  &&
              //  assessorData?.data?.data?.assessmentNames.length > 0
              <ReactApexChart
                options={options}
                series={options.series}
                type="bar"
                className="chart"
              />
            ) : (
              <div className="flex justify-center items-center h-80">
                No Data Found
              </div>
            )}
          </div>
          <div className="xs:text-xs sm:text-sm xl:h-[49%] md:h-[50%]  bg-white shadow-sm p-2 rounded xl:mt-0 sm:mt-1 xs:mt-2">
            {assessorData?.data?.data2?.lessThan50.length > 0 &&
              assessorData?.data?.data2?.Bet50To75.length > 0 &&
              assessorData?.data?.data2?.Bet76To90.length > 0 &&
              assessorData?.data?.data2?.Above91.length > 0 ? (
              //  &&
              //  assessorData?.data?.data?.assessmentNames.length > 0
              <ReactApexChart
                options={options2}
                series={options2.series}
                type="bar"
                className="chart"
              />
            ) : (
              <div className="flex justify-center items-center h-80">
                No Data Found
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default AssessorDashboard
