/* eslint-disable no-unexpected-multiline */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-debugger */
import { useEffect, useMemo, useState } from 'react'
import DataTable from '../Layouts/DataTable.js'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { BiSearch } from 'react-icons/bi/index.js'
import SendMail from '../Modal/SendMail.js'
import {
  bulkDeleteAssessment,
  getAllAssessments,
  updateAssessment,
} from '../../Store/assessmentSlice.js'
import debounce from 'lodash.debounce'
import { images } from '../../Constants/image.constant.js'
import DeleteAssessment from '../Modal/DeleteAssessment.js'
import Breadcrumbs from '../Common/Breadcrumbs.js'
import { toast } from 'react-hot-toast'
import { resetValue } from '../../Store/createAssesmentSlice.js'
import { useTranslation } from 'react-i18next'
import { RiDeleteBin3Fill } from 'react-icons/ri'
import { FaShareSquare } from 'react-icons/fa'
import SwitchButton from '../Common/SwitchButton.js'
import { appConstants } from '../../Constants/app.constant.js'
import Loader from '../Common/Loader.js'

const Dashboard = () => {
  const status = [
    { id: 1, status: 'Active' },
    { id: 2, status: 'Inactive' },
  ]
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { state } = useLocation()
  const [assessmentList, setAssessmentList] = useState([])
 
  const [pageCount, setPageCount] = useState(0)
  const [offset, setOffset] = useState(state?.offset ? state?.offset : 1)
  const [isOpen, setIsOpen] = useState(false)
  const [isShow, setIsShow] = useState(false)
  const [assessmentDetail, setAssessmentDetail] = useState(null)
  const [search, setSearch] = useState('')
  const [assessmet_id, setAssessmentId] = useState(null)
  const [filterByStatus, setFilterByStatus] = useState(
    state?.label ? state?.label : ''
  )
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false)
  const [selectedIds, setSelectedIds] = useState([])
  const [isAllSelect, setIsAllSelect] = useState(false)
  const locatData = useSelector((state) => state.assessment)
  const isBulkDelete = locatData?.loading
  
  const { t } = useTranslation()
  const [limit, setLimit] = useState(appConstants.LIMIT)
  const [isShowBulkDelete,setIsShowBulkDelete]= useState(false);
  // const limit = appConstants.LIMIT

  const assessmentData = useSelector((state) => state.assessment)

  // const isLoading = assessmentData?.loading
  const breadcrumbItems = [
    { label: t('dashboard'), url: '/dashboard' },
    { label: t('assessment'), url: '/assessments' },
  ]
  const handleLimit = (e) => {

    Number(e.target.value) === 0
      ? setLimit(10)
      : setLimit(Number(e.target.value))
  }
  const SendMailMemoized = useMemo(() => {
    return (
      <SendMail isOpen={isOpen} setIsOpen={setIsOpen} data={assessmentDetail} />
    )
  }, [isOpen, setIsOpen, assessmentDetail])
  const handleSelectedIds=()=>{
    setSelectedIds([])
  }
  const DeleteAssessmentMemoized = useMemo(() => {
    if(isShowBulkDelete)
      {
        return (
     
          <DeleteAssessment
            isOpen={isShow}
            setIsOpen={setIsShow}
            setSelectedIds={handleSelectedIds}
            setIsShowBulkDelete={isShowBulkDelete}
             data={{ assessments: selectedIds }}
          />
        
        )
        
      }
      else{
        return (
     
          <DeleteAssessment
            isOpen={isShow}
            setIsOpen={setIsShow}
             data={{ assessment_id: assessmet_id }}
          />
        )
      }
    
    
  }, [isShow, setIsShow, assessmet_id,selectedIds])

  useEffect(() => {
    if (
      assessmentData &&
      assessmentData.getAllAssessments &&
      assessmentData.getAllAssessments.assessments
    ) {
      setAssessmentList(assessmentData.getAllAssessments.assessments)
      setPageCount(assessmentData.getAllAssessments.pages)
      setIsLoading(false)
      setIsLoadingRefresh(false)
    }
  }, [assessmentData.getAllAssessments])

  const handlePageClick = (page) => {
    setOffset(page.selected + 1)
  }

  const handleInviteLink = (data) => {
    if (data.assessment_status) {
      setAssessmentDetail(data)
      setIsOpen(!isOpen)
    } else {
      toast.error(t('candidateNotInvited'))
    }
  }

  // const handleIdClick = (assessment_id, assessment_url) => {
  //     navigate('/dashboard/assessment/' + assessment_id + '/' + assessment_url)
  // }

  const handleDeleteAssessment = (event, assessment_id) => {
    event.stopPropagation()
    setAssessmentId(assessment_id)
    setIsShow(!isShow)
  }

  useEffect(() => {
    const debouncedGetData = debounce(async () => {
      let obj = {
        offset: offset,
        limit: limit,
        query: search,
        filter: {
          status:
            filterByStatus === 'Active'
              ? 1
              : filterByStatus === 'Inactive'
              ? 0
              : null,
        },
      }
      setIsLoading(true)
      await dispatch(getAllAssessments(obj))
    }, 0) // Example debounce with 300ms delay

    debouncedGetData() // Initial call

    return () => {
      debouncedGetData.cancel() // Cancel any pending API call on cleanup
    }
  }, [dispatch, offset, limit, search, filterByStatus])

  const handleChange = (e) => {
    setSearch(e.target.value)
    setFilterByStatus('')
  }
  const handleFilterByStatus = (e) => {
    setIsLoadingRefresh(true)
    setFilterByStatus(e.target.value)
    setSearch('')
  }
  const handleRowClick = (data) => {
    const { _id, assessment_url } = data
    navigate('/assessments/' + _id + '/' + assessment_url, {
      state: { offset: offset },
    })
  }
  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      setIsAllSelect(true)
    } else {
      setIsAllSelect(false)
    }
  }

  const handleRowChange = (event, id) => {
    event.stopPropagation()
    const index = selectedIds?.indexOf(id)
    if (index === -1) {
      setSelectedIds([...selectedIds, id])
    } else {
      setSelectedIds(selectedIds.filter((_id) => _id !== id))
    }
  }

  useEffect(() => {
    if (isAllSelect) {
      setSelectedIds(assessmentList.map((row) => row._id))
    } else {
      setSelectedIds([])
    }
  }, [isAllSelect])

  const handleStatusChange = async (event, data) => {
    // Prevent the default behavior if needed
    // event.preventDefault();

    let obj = {
      assessment_id: data._id,
      status: event.target.checked ? 1 : 0,
    }

    let tempArrList = [...assessmentList]

    const updatedTempArrList = tempArrList.map((assessmentData) => {
      if (assessmentData._id === data._id) {
        return {
          ...assessmentData,
          assessment_status: event.target.checked ? 1 : 0,
        }
      }

      return assessmentData
    })
    setAssessmentList(updatedTempArrList)

    await dispatch(updateAssessment(obj))

    // let obj2 = {
    //     offset: offset,
    //     limit: limit,
    //     query: search
    // };

    // If you need to wait for getAllAssessments, uncomment the line below
    // await dispatch(getAllAssessments(obj2));
  }

  const columns = [
    {
      Header: (
        <div className="w-100">
          <input
            type="checkbox"
            checked={isAllSelect}
            ref={(input) => {
              if (input) {
                input.indeterminate =
                  selectedIds?.length > 0 &&
                  assessmentList.length !== selectedIds.length
              }
            }}
            className="d-block w-100 cursor-pointer"
            onChange={(event) => handleSelectAllChange(event)}
          />
        </div>
      ),
      accessor: '_id',
      disableSortBy: true,
      Cell: ({ row }) => (
        <div onClick={(event) => event.stopPropagation()}>
          <input
            type="checkbox"
            checked={selectedIds.includes(row.original._id)}
            onChange={(event) => handleRowChange(event, row.original._id)}
            className={`cursor-pointer ${
              selectedIds.length ? 'showcheckbox' : 'cardcheckbox'
            }`}
          />
        </div>
      ),
    },
    { Header: t('name'), accessor: 'assessmentName',
      Cell:({row})=>(
        <div title={row?.original?.assessmentName}>
          {row?.original?.assessmentName.length>22 ? row?.original?.assessmentName.slice(0,22)+'...' : row?.original?.assessmentName}
        </div>
      )
     },
    {
      Header: t('date'),
      accessor: 'createdAt',
      Cell: ({ row }) => (
        <div className="flex items-center justify-center">
          {` ${new Date(row.original.createdAt).getDate()} ${new Date(
            row.original.createdAt
          ).toLocaleString('en-US', { month: 'short' })}   ${new Date(
            row.original.createdAt
          ).getFullYear()}`}
        </div>
      ),
    },
    {
      Header: t('duration'),
      accessor: 'assessment_duration',
      Cell: ({ row }) => (
        <div className="flex items-center justify-center">
          {row.original.assessment_duration}
        </div>
      ),
    },
    {
      Header: t('inviteCandidates'),
      accessor: 'inviteCandidates',
      disableSortBy: true,
      Cell: ({ row }) => (
        // <div>
        //     <BsFillCloudArrowUpFill data-testid="assessment-invite" className="text-[rgb(0,157,255)] text-lg cursor-pointer text-center ml-9 " onClick={(e) => { e.stopPropagation(); handleInviteLink(row.original) }} />
        // </div>
        <div className="flex items-center justify-center">
          <FaShareSquare
            data-testid="assessment-invite"
            className="text-[rgb(0,157,255)] text-lg cursor-pointer"
            onClick={(e) => {
              e.stopPropagation()
              handleInviteLink(row.original)
            }}
            title="Share"
          />
        </div>
      ),
    },
    {
      Header: t('status'),
      accessor: 'assessment_status',
      disableSortBy: true,
      Cell: ({ row }) => (
        <div
          className="flex items-center justify-center"
          onClick={(e) => e.stopPropagation()}
        >
          {/* <Switch data-testid="assessment-status-toggle" onChange={(status) => { handleStatusChange(row.original, status) }} checked={row.original.assessment_status === 1 ? true : false} /> */}

          <SwitchButton
            data={row.original}
            status={row?.original?.assessment_status}
            handleStatusChange={handleStatusChange}
            data-testid="assessment-status-toggle"
          />
          {/* <div className= {`switch-btt ${row.original.assessment_status === 1 ? 'checked-y': 'checked-n'}`}>
                <input type='checkbox' name='' className='toggle' checked={row.original.assessment_status === 1 ? true : false} onChange={(event) => { handleStatusChange(event, row.original) }} />
                <div className='slide'></div>
              </div> */}
        </div>
      ),
    },
    {
      Header: 'Action',
      accessor: 'action',
      disableSortBy: true,
      Cell: ({ row }) => (
        <div className="flex items-center justify-center">
          <RiDeleteBin3Fill
            className=" text-red-500 text-xl cursor-pointer "
            onClick={(event) => handleDeleteAssessment(event, row.original._id)}
            title="Delete"
          />
          {/* <FaEye className="cursor-pointer text-[rgb(0,157,255)] text-lg ml-2" onClick={(e) => {e.stopPropagation(); handleIdClick(row.original._id, row.original.assessment_url)}} /> */}
        </div>
      ),
    },
  ]

  const handleClick = () => {
    dispatch(resetValue())
    navigate('/assessments/createAssessment')
  }

  const handleSubmitBulkDelete = async() => {
    try {
      setIsShowBulkDelete(true)
      
      setIsShow(!isShow)
      // await dispatch(bulkDeleteAssessment({ assessments: selectedIds }))
      // let obj = {
      //   offset: offset,
      //   limit: limit,
      //   query: search,
      //   filter: {
      //     status:
      //       filterByStatus === 'Active'
      //         ? 1
      //         : filterByStatus === 'Inactive'
      //         ? 0
      //         : null,
      //   },
      // }
      // await dispatch(getAllAssessments(obj))
      // setSelectedIds([])
      // setIsShowBulkDelete(false)
    } catch (err) {
      console.error('err', err)
    }
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <div
      data-testid="dashboard"
      className={`p_five bgc-color w-full overflow-y-hidden ${
        window.screen.height >= 900
          ? 'screen-height'
          : assessmentList.length > 6
          ? 'h-auto'
          : 'screen-height'
      }`}
    >
      {/* <div
      data-testid="dashboard"
      className={`p_five bgc-color w-full overflow-y-hidden ${window.screen.height >= 900 ? 'h-[90vh] lg:h-[92vh]' : assessmentList.length > 9 ? 'h-auto': 'h-[90vh] lg:h-[92vh]'}`}
    > */}
      <Breadcrumbs items={breadcrumbItems} />
      <div className="flex flex-col justify-start items-start sm:justify-between  xs:items-start xl:flex-row mt-4">
        {/* <div className="no-underline" onClick={handleClick}>
                    <button  className="px-6 py-2 bg-[rgb(0,157,255)] hover:bg-[rgb(30,66,159)] text-white rounded-md no-underline font text-xs sm:text-xs lg:text-base md:text-base xl:text-base 2xl:text-base">{t('createAssessment')}</button>
                </div> */}

        <div className=" flex items-center border rounded-lg px-3 shadow-sm bg-white sm:mb-3 ">
          <div className="">
            <BiSearch className="text-lg" />
          </div>
          <div className="">
            <input
              type="text"
              data-testid="search-input"
              placeholder={t('search')}
              value={search}
              className="rounded-md bg-white sm:w-64 px-3 py-1 mb-1 sm:mb-0 border-none focus:border-none focus:outline-none "
              onChange={handleChange}
            />
          </div>
        </div>

        {selectedIds?.length > 0 && (
          <div className="flex items-center">
            <button
              type="button"
              onClick={handleSubmitBulkDelete}
              className=" xs:mt-5 sm:mt-1 xl:mt-0 text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm w-28 py-2 text-center"
            >
              {isBulkDelete ? (
                <span className="spinner-border spinner-border-sm text-light"></span>
                // <></>
              ) : (
                'Delete Selected'
              )}
            </button>
          </div>
        )}

        <div className="flex items-center xl:justify-end xs:mt-3 xl:mt-0 w-full xl:w-1/2">
          <div>
            <select
              name="status"
              value={filterByStatus}
              data-testid="plan-input"
              onChange={(e) => handleFilterByStatus(e)}
              required
              className="font-bold block w-full px-3  text-xs sm:text-sm  h-10 border-2 rounded-md border-blue-300 hover:border-green-200 outline-blue-300  bgc-color  "
            >
              <option value="" selected>
                Filter Status: All
              </option>
              {status.map((data) => {
                return (
                  <option key={data.id} name={data.id} value={data.status}>
                    {data.status === 'In_Progress'
                      ? 'In-Progress'
                      : data.status}
                  </option>
                )
              })}
            </select>
          </div>
          <div className=" group flex xs:mt-3 xl:mt-0">
            {/* <MdCreateNewFolder className="text-4xl text-color cursor-pointer" onClick={handleClick} /> */}
            <img
              data-testid="create-img"
              src={images.CREACTE_ICON}
              alt="create"
              className=" w-16 md:py-0 cursor-pointer"
              onClick={handleClick}
              title={t('crtAssessment')}
            />
            {/* <span
            data-testid="create-label"
            className="absolute top-[17rem] lg:top-56 xl:top-56 scale-0 rounded border-1 border-gray-400  p-1 text-xs text-black group-hover:scale-100"
          >
            {t('crtAssessment')}
          </span> */}
          </div>
        </div>
      </div>
      {isLoading && (
        <div>
          <div
            className="spinner-border text-primary absolute top-50 z-1 "
            style={{ left: '50%', bottom: '20%' }}
          ></div>
        </div>
      )}
      <DataTable
        columns={columns}
        data={assessmentList}
        handlePageClick={handlePageClick}
        pageCount={pageCount}
        handleRowClick={handleRowClick}
        offset={offset}
        handleLimit={handleLimit}
        // setSelectedIds={setSelectedIds}
      />

      {assessmentDetail && SendMailMemoized}
      {DeleteAssessmentMemoized}
      {/* <SendMail isOpen={isOpen} setIsOpen={setIsOpen} data={assessmentDetail} /> */}
      {/* <DeleteAssessment isOpen={isShow} setIsOpen={setIsShow} data={{ assessment_id: assessmet_id }} /> */}
    </div>
  )
}

export default Dashboard
