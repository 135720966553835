import { FaUsers } from 'react-icons/fa'
import { LuNewspaper } from 'react-icons/lu'
import { FaFileCircleCheck, FaFileCircleXmark } from 'react-icons/fa6'
import { PiMicrosoftTeamsLogo } from 'react-icons/pi'
import {
  Chart as ChartJS,
  PolarAreaController,
  RadialLinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js'
import ReactApexChart from 'react-apexcharts'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSuperAdminDashboard } from '../../Store/dashboardSlice'
import Loader from '../Common/Loader'
import AssessorDashboard from '../AssessorDashboard'

ChartJS.register(
  PolarAreaController,
  RadialLinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Tooltip,
  Legend
)
const HomeDashboard = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const localState = useSelector((state) => state.dashboard)
  const userData = JSON.parse(localStorage.getItem('userData'))
  const isLoading = localState.loading
  const [isStatus, setIsStatus] = useState('')
  const [isAssStatus, setIsAssStatus] = useState('')
  const [dashboardData, setDashboardData] = useState({})
  const [byStatusData, setByStatusData] = useState([])
  const [AssByStatus, setAssByStatus] = useState([])
  const [members, setMembers] = useState({})
  const [labels, setLabels] = useState([])
  const [assessorData, setAssessorData] = useState(null)
  const [assessmentCount, setAssessmentCount] = useState({})
  const [candidateCount, setCandidateCount] = useState({})
  const handleClick = (label) => {
    navigate('/allCandidate', { state: { label: label } })
  }
  const handleAssClick = (label) => {
    navigate('/assessments', { state: { label: label } })
  }




  const assesmentStatus = {
    series: AssByStatus || [],
    options: {
      chart: {
        type: 'donut',
        events: {
          legendClick: (chartContext, seriesIndex, config) => {
            const label = config.config.labels[seriesIndex]
            if (label) {
              handleAssClick(label)
            }
          },
          dataPointSelection: (event, chartContext, config) => {
            const label = config.w.config.labels[config.dataPointIndex]
            if (label) {
              setIsAssStatus(label)
            }
          },
        },
      },
      tooltip: {
        enabled: true,
        onDatasetHover: {
          highlightDataSeries: false,
        },
        y: {
          formatter: function (
            value,
            { series, seriesIndex, dataPointIndex, w, config }
          ) {
            // console.log('value', series,seriesIndex,dataPointIndex,w,config)
            const label = config.labels[seriesIndex]
            const count = localState?.superAdminDashboard?.data?.assessmentCounts[label]

            if (count !== undefined ) {
              return count
            }
            return value
          },
        },
      },
      colors: ['#4CAF50', '#e60026'],
      labels: ['Active', 'Inactive'],
      responsive: [
        {
          breakpoint: 1801,
          options: {
            chart: {
              width: 600,
              height: 600,
            },

            legend: {
              position: 'right',
            },
          },
        },
        {
          breakpoint: 1441,
          // height:813 
          options: {
            chart: {
              width: 500,
              height: 800,
            },

            legend: {
              position: 'right',
            },
          },
        },
        // {
        //   breakpoint: 1310,
        //   // height:813 
        //   options: {
        //     chart: {
        //       width: 520,
        //       height: 800,
        //     },

        //     legend: {
        //       position: 'right',
        //     },
        //   },
        // },
        {
          breakpoint: 1281,
          options: {
            chart: {
              width: 400,
              height: 300,
            },

            legend: {
              position: 'right',
            },
          },
        },

        {
          breakpoint: 994,
          options: {
            chart: {
              width: 350,
              height: 300,
            },
            legend: {
              position: 'right',
            },
          },
        },
        {
          breakpoint: 768,
          options: {
            chart: {
              width: 350,
              height: 400,
            },
            legend: {
              position: 'right',
            },
          },
        },

        {
          breakpoint: 477,
          options: {
            chart: {
              width: 280,
              height: 300,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
        {
          breakpoint: 400,
          options: {
            chart: {
              width: 250,
              height: 300,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
        {
          breakpoint: 350,
          options: {
            chart: {
              width: 210,
              height: 300,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
     
    },
  }

  const data = {
    series: byStatusData || [],
    options: {
      chart: {
        type: 'donut',
        events: {
          legendClick: (chartContext, seriesIndex, config) => {
            const label = config.config.labels[seriesIndex]
            if (label) {
              handleClick(label)
            }
          },
          dataPointSelection: (event, chartContext, config) => {
            const label = config.w.config.labels[config.dataPointIndex]
            if (label) {
              setIsStatus(label)
            }
          },
        },
      },
      tooltip: {
        enabled: true,
        onDatasetHover: {
          highlightDataSeries: false,
        },
        y: {
          formatter: function (
            value,
            { series, seriesIndex, dataPointIndex, w, config }
          ) {
            // console.log('value', series,seriesIndex,dataPointIndex,w,config)
            const label = config.labels[seriesIndex]
      
            const count = candidateCount[label]
            if (count !== undefined) {
              return count
            }
            // return value
          },
        },
      },
      colors: ['#4CAF50', '#FFC107', '#03A9F4', '#9370db'],
      labels: labels || [],
      responsive: [
        {
          breakpoint: 1801,
          options: {
            chart: {
              width: 620,
              height: 600,
            },

            legend: {
              position: 'right',
            },
          },
        },
        {
          breakpoint: 1441,
          // height:813 
          options: {
            chart: {
              width: 520,
              height: 800,
            },

            legend: {
              position: 'right',
            },
          },
        },


        {
          breakpoint: 1281,
          options: {
            chart: {
              width: 420,
              height: 300,
            },

            legend: {
              position: 'right',
            },
          },
        },
        
        {
          breakpoint: 994,
          options: {
            chart: {
              width: 370,
              height: 300,
            },
            legend: {
              position: 'right',
            },
          },
        },
        {
          breakpoint: 768,
          options: {
            chart: {
              width: 370,
              height: 400,
            },
            legend: {
              position: 'right',
            },
          },
        },

        {
          breakpoint: 477,
          options: {
            chart: {
              width: 310,
              height: 300,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
        {
          breakpoint: 400,
          options: {
            chart: {
              width: 250,
              height: 300,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
        {
          breakpoint: 350,
          options: {
            chart: {
              width: 220,
              height: 300,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  }

  

  useEffect(() => {
    if (isStatus) {
      handleClick(isStatus)
    }
  }, [isStatus])

  useEffect(() => {
    if (isAssStatus) {
      handleAssClick(isAssStatus)
    }
  }, [isAssStatus])

  useEffect(() => {
    dispatch(getSuperAdminDashboard())
  }, [dispatch])

  useEffect(() => {
    if (userData?.role?.roleType === 2 || userData?.role?.roleType === 3) {
      if (
        localState &&
        localState?.superAdminDashboard &&
        localState?.superAdminDashboard?.data
      ) {
        setAssessmentCount(
          localState?.superAdminDashboard?.data?.assessmentCounts
        )
        setCandidateCount(
          localState?.superAdminDashboard?.data?.CandidateCounts
        )
        const data =
          localState?.superAdminDashboard?.data?.candidateMetric?.data
        const assessmentData =
          localState?.superAdminDashboard?.data?.assessments_status
        if (assessmentData?.length > 0) {
          const actualData = assessmentData.map((str) => parseFloat(str))
          setAssByStatus(actualData)
        }
        if (data?.length > 0) {
          const actualData = data.map((str) => parseFloat(str))
          setByStatusData(actualData)
        }
        setLabels(
          localState?.superAdminDashboard?.data?.candidateMetric?.labels
        )
        setDashboardData(localState?.superAdminDashboard?.counts)
        setMembers(localState?.superAdminDashboard?.data?.members)
       
      }
    } else if (userData?.role?.roleType === 5) {
      setAssessorData(localState?.superAdminDashboard)
    }
  }, [localState?.superAdminDashboard])

  // console.log('state', localState)

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        ((userData?.role?.roleType === 2 || userData?.role?.roleType === 3) && (
          <div
            data-testid="dashboard"
            className="plt bgc-color w-full overflow-hidden dashboard"
          >
            <div>
              <section className="statistics">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="box d-flex rounded-2 align-items-center mb-4 mb-lg-0 p-3 shadow-md">
                      <i className="uil-envelope-shield fs-2 text-center bg-primary rounded-circle flex justify-center items-center">
                        <LuNewspaper className="text-light" />
                      </i>
                      <div className="ms-3">
                        <div className="d-flex align-items-center">
                          <h3 className="mb-0">
                            {dashboardData?.totalAssessments}
                          </h3>{' '}
                          <span className="d-block ms-2">Assessments</span>
                        </div>
                        <p className="fs-normal mb-0">Total Assessments</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="box d-flex rounded-2 align-items-center mb-4 mb-lg-0 p-3 shadow-md">
                      <i className="uil-file fs-2 text-center bg-danger rounded-circle flex justify-center items-center">
                        <PiMicrosoftTeamsLogo className="text-light" />
                      </i>
                      <div className="ms-3">
                        <div className="d-flex align-items-center">
                          <h3 className="mb-0">
                            {dashboardData?.totalMembers}
                          </h3>{' '}
                          <span className="d-block ms-2">
                            {' '}
                            {members ? 'Team Members' : 'Assessor'}{' '}
                          </span>
                        </div>
                        {members ? (
                          <p className="fs-normal mb-0">
                            <span className="text-color">
                              {members?.admin ? members?.admin : 0}
                            </span>{' '}
                            Admins |{' '}
                            <span className="text-color">
                              {members?.assessor ? members?.assessor : 0}
                            </span>{' '}
                            Assessors
                          </p>
                        ) : (
                          <p className="fs-normal mb-0">Total Assessor</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="box d-flex rounded-2 align-items-center p-3 shadow-md">
                      <i className="uil-users-alt fs-2 text-center bg-success rounded-circle flex justify-center items-center">
                        <FaUsers className="text-light" />
                      </i>
                      <div className="ms-3">
                        <div className="d-flex align-items-center">
                          <h3 className="mb-0">
                            {dashboardData?.totalCandidates}
                          </h3>{' '}
                          <span className="d-block ms-2">Candidates</span>
                        </div>
                        <p className="fs-normal mb-0">Total Candidates</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <section className=" chart-container w-[100%]">
              <div className="xs:h-[70vh] sm:h-[70vh] md:h-[60vh] lg:h-[60vh] xl:h-[60vh] 2xl:h-[90vh] mt-3 w-[100%] bg-white shadow-md rounded sm:w-[100%] md:w-[100%] lg:w-[49%] xl:w-3/2 2xl:w-1/2">
                <div className="w-96">
                  <p className="text-s font-semibold font p-3">
                  Assessment By Status
                  </p>
                  <ReactApexChart
                    options={assesmentStatus.options}
                    series={assesmentStatus.series}
                    type="donut"
                    className="chart"
                  />
                </div>
              </div>
              <div className="xs:h-[70vh] sm:h-[70vh] md:h-[60vh] lg:h-[60vh] xl:h-[60vh] 2xl:h-[90vh]  mt-3 w-[100%] bg-white shadow-md rounded sm:w-[100%] md:w-[100%] lg:w-[49%] xl:w-3/2 2xl:w-1/2 md:ml-4">
                <div className="w-96">
                  <p className="text-s font-semibold font p-3">
                    Candidate By Status
                  </p>
                  <ReactApexChart
                    options={data.options}
                    series={data.series}
                    type="donut"
                    className="chart"
                  />
                </div>
              </div>
            </section>

            
          </div>
        )) ||
        (userData?.role?.roleType === 5 && (
          <AssessorDashboard assessorData={assessorData} />
        ))
      )}
    </>
  )
}

export default HomeDashboard
