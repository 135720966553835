export const images= {
    LOGO: 'https://testainty-images.s3.ap-south-1.amazonaws.com/profilePicture/Main-logo.svg',
    DataNotFound: '/assets/images/404-img.png',
    CREACTE_ICON: '/assets/images/crt.png',
    HOME: '/assets/images/s.svg',
    ABOUT: '/assets/images/a.svg',
    SERVICE1: '/assets/images/image1-r.jpg',
    SERVICE2: '/assets/images/recruitment-process2.jpeg',
    SERVICE3: '/assets/images/recruiting-skills-cover.webp',
    FOOTERLOGO: '/assets/images/footer-logo-2.svg',
    NOTIFICATION: '/assets/images/notification-icon.png',
    REFRESH: '/assets/images/refresh.png',
    AudioTes: '/assets/audios/66ed0d0fe5817c3e9bc1ac14_20240920_112232.webm',
    WAVE: '/assets/images/wave.png'

}

